export const FirebaseConfig = {
	"projectId": "mobigo-e60a6",
	"appId": "1:66652679673:web:f22a16185c20a81076a128",
	"databaseURL": "https://mobigo-e60a6-default-rtdb.firebaseio.com",
	"storageBucket": "mobigo-e60a6.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCL2E7S0v-yNqg5WB6LpIWWjn-F8VVX2no",
	"authDomain": "mobigo-e60a6.firebaseapp.com",
	"messagingSenderId": "66652679673",
	"measurementId": "G-YVBJKT4826"
};