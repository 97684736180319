export const colors = {
    LandingPage_Front : '#F1F2F1',
    LandingPage_Background: '#E9E9E1',
    LandingPage_Description: '#000000',
    Button_Primary: '#000000',
    Button_Secondary: '#000000', 
    Header_Background: "#FFFFFF",
    Header_Content: '#000000',
    AppMenu_Header: '#FFF',
    ResponsiveDrawer_Header: '#000000',
}